export default function () {

    const ApiLessons = useApi('lessons');
    const EntityLesson = useEntityLesson();

    async function get (filter) {
        let lessons = null, error = null;
        try {
            const {data} = await ApiLessons.get('lessons', filter);
            lessons = data.lessons.map((lesson) => {
                return EntityLesson.build(lesson);
            });
        } catch (e) {
            error = useApiError(e);
        }
        return {lessons, error};
    }

    const ApiCancel = useApiCancel();

    async function filtered (filter, cancelKey) {
        let lessons = null, error = null, hasMore = false;
        const signal = ApiCancel.supersede(cancelKey);
        try {
            const {data} = await ApiLessons.get('lessons-filtered', filter, signal);
            ApiCancel.remove(cancelKey);
            lessons = data.lessons.map((lesson) => {
                return EntityLesson.build(lesson);
            });
            hasMore = (lessons.length === filter?.paginate?.limit);
        } catch (e) {
            error = useApiError(e);
        }
        return {lessons, error, hasMore};
    }

    return {get, filtered};
}
